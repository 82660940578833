<!--  -->
<template>
  <div class="rules-pages">
    <!-- <TopWrapper :topObj="topObj" /> -->
    <div class="main-wrapper">
      <div class="tab-box">
        <van-tabs
          v-model="activeName"
          title-active-color="#0094EE"
          color="#0094EE"
          @change="changeTab"
        >
          <van-tab
            v-for="(tab, i) in tabs"
            :key="i"
            :title="tab.dictValue"
            :name="tab.bizCode"
          >
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
              <van-list
                v-if="tableData.length > 0"
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="getList"
                class="list-box"
              >
                <van-cell
                  v-for="(item, i) in tableData"
                  :key="i"
                  class="item"
                  @click="handleApply(item)"
                >
                  <div class="item-box">
                    <van-tag
                      v-if="item.isTop == 1"
                      plain
                      class="unget"
                      round
                      type="primary"
                      >置顶</van-tag
                    >
                    <p slot="title" class="title">
                      {{ item.naTitle }}
                    </p>
                  </div>
                  <div class="time">
                    {{ item.onlineTime }} &nbsp;<van-icon
                      name="arrow"
                      size="14"
                    />
                  </div>
                </van-cell>
              </van-list>
              <van-empty v-else description="暂无数据"></van-empty>
            </van-pull-refresh>
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { getDictListByCode, getNoticeList } from "@/api/notice/index.js";
import TopWrapper from "@/components/topWrapper/index.vue";

export default {
  data() {
    return {
      topObj: {
        bgColor: "#0094EE",
        title: "通知公告列表",
        txtColor: "#fff",
        isBack: true,
        isMsg: false,
      },
      activeName: "",
      tabs: [],
      listQuery: {
        pageNum: 1,
        pageSize: 10,
        dictCode: "",
        isOnline: 1,
        isOwnerPublish: 0,
        publishBeginTime: "",
        publishEndTime: "",
        subStaffName: "",
        naTitle: "",
        isOnlyNotice: 0,
        homePageType: 1,
        naType: 2,
      },
      tableData: [],
      loading: false,
      finished: false,
      refreshing: false,
      tableTotal: 0,
    };
  },

  components: { TopWrapper },

  computed: {},

  mounted() {
    this.getDics();
  },

  methods: {
    handleApply(item) {
      this.$router.push({
        path: "/afficheDetail",
        query: {
          id: item.naCode,
        },
      });
    },
    changeTab(name) {
      this.finished = false;
      this.loading = false;
      this.refreshing = false;
      this.listQuery.pageNum = 1;
      this.activeName = name;
      this.tableData = [];
      this.getList();
    },
    /* 获取类型 */
    getDics() {
      getDictListByCode({ code: "notice" }).then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            this.tabs = data;
            this.activeName = data[0].bizCode;
            this.getList();
          }
        }
      });
    },
    /* 获取通知 */
    getList() {
      this.loading = true;
      const params = {
        ...this.listQuery,
        dictCode: this.activeName,
      };
      getNoticeList(params).then((res) => {
        this.loading = false;
        this.refreshing = false;
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            this.tableData = this.tableData.concat(data.records);
            this.tableTotal = data.total;
            if (this.tableData.length >= data.total) {
              //如果没有数据了，把finished设置为true，之后就不会触发加载更多
              this.finished = true;
            } else {
              if (this.listQuery.pageNum >= data.pages) {
                //如果没有数据了，把finished设置为true，之后就不会触发加载更多
                this.finished = true;
              } else {
                this.listQuery.pageNum++;
              }
            }
          }
        }
      });
    },
    onRefresh() {
      // this.tableData = [];
      // this.listQuery.pageNum = 1;
      // this.finished = false; //清空类表数据
      // this.loading = true; //加载状态
      // this.refreshing = false;
      // this.getList();
      if (this.tableData.length < this.tableTotal) {
        this.finished = false; //清空类表数据
        this.loading = true; //加载状态
        this.getList();
      } else {
        this.loading = false;
        this.refreshing = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.rules-pages {
  width: 100%;
  height: auto;
  min-height: 100%;
  background: #fafafa;
  position: relative;
  .main-wrapper {
    width: 100%;
    .list-box {
      margin: 0.3rem;
      padding: 0rem 0.3rem;
      background: #fff;
      .item {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        cursor: pointer;
        height: 1rem;
        line-height: 1rem;
        .item-box {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          overflow: hidden;
          align-items: center;
          flex: 1;
          .title {
            font-weight: 400;
            color: #333333;
            font-size: 0.3rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            flex: 1;
          }
          .unget {
            margin-right: 0.1rem;
            width: 0.7rem;
            height: 0.34rem;
            line-height: 0.4rem;
            font-size: 0.2rem;
            background: rgba(0, 148, 238, 0.1);
            // padding: 0.01rem 0.12rem;
            text-align: center;
          }
          /deep/ .van-tag {
            font-size: 0.2rem;
          }
        }

        .time {
          width: 3rem;
          text-align: right;
          font-size: 0.22rem;
          color: #999999;
        }
      }
      ::v-deep .van-cell {
        padding: 0.3rem 0rem;
      }
      ::v-deep .van-cell::after {
        border-bottom: 1px solid #e5e5e5;
      }
      ::v-deep .van-cell__value {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
}
</style>
